import ErrorStackParser from 'error-stack-parser'

const rollbarUrl = `https://api.rollbar.com/api/1/item/`

// https://github.com/rollbar/rollbar.js/blob/master/src/errorParser.js
function Frame(stackFrame: ErrorStackParser.StackFrame) {
    var data: any = {};

    // data._stackFrame = stackFrame;

    data.filename = stackFrame.fileName;
    data.lineno = stackFrame.lineNumber;
    data.colno = stackFrame.columnNumber;
    data.method = stackFrame.functionName;
    data.args = stackFrame.args;

    return data;
}

export interface RollbarSettings {
    environment: string;
    version: string;
    clientKey: string;
}
export interface RollbarReportParameters {
    error: Error;
    description?: string;
    telemetryType?: string;
    method?: string;
    url?: string;
    statusCode?: string;
}

export const rollbarError = async (rollbarSettings: RollbarSettings, reportParams: RollbarReportParameters) => {
    const stack = ErrorStackParser.parse(reportParams.error)
    const rollbarBody = {
        "access_token": rollbarSettings.clientKey,
        "data": {
            "environment": rollbarSettings.environment,
            "enabled": rollbarSettings.environment === "local" ? false : true,
            "body": {
                "telemetry": [{
                    "level": "error",
                    "source": "server",
                    "timestamp_ms": Date.now(),
                    "type": reportParams.telemetryType ?? "error",
                    "body": reportParams.telemetryType === "network" ? {
                        "method": reportParams.method,
                        "url": reportParams.url,
                        "status_code": reportParams.statusCode
                    } : undefined
                }],
                "trace": {
                    "frames": stack.map(stackFrame => Frame(stackFrame)),
                    "exception": {
                        "class": reportParams.error.name,
                        "message": reportParams.error.message,
                    }
                }
            }
        }
    }
    if (reportParams.description) {
        Object.assign(rollbarBody.data.body.trace.exception, { description: reportParams.description })
    }
    const rollbarRequest = new Request(rollbarUrl, {
        method: 'POST',
        body: JSON.stringify(rollbarBody)
    })
    const rollbarResponse = await fetch(rollbarRequest);
    return rollbarResponse;
}

export const rollbarMessage = async (rollbarSettings: RollbarSettings, message: string, attributes: {} | null) => {
    const rollbarBody = {
        "access_token": rollbarSettings.clientKey,
        "data": {
            "environment": rollbarSettings.environment,
            "enabled": rollbarSettings.environment === "local" ? false : true,
            "body": {
                "message": {
                    "body": message,
                }
            }
        }
    }
    if (attributes) {
        Object.assign(rollbarBody.data.body.message, attributes)
    }

    const rollbarRequest = new Request(rollbarUrl, {
        method: 'POST',
        body: JSON.stringify(rollbarBody)
    })
    const rollbarResponse = await fetch(rollbarRequest)
    return rollbarResponse
}
